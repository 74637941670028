import { css, styled } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

import { BooleanFilter } from '@playq/octopus-common';

import { getOppositeColor } from '/helpers';
import { IThemeDependentStyledComponentProps } from '/common/models';
import { DEFAULT_SELECTED_SEARCH_ICON_COLOR, INITIAL_SEARCH_ICON_COLOR } from '/shared/Table';

export const SELECTED_MISSED_SEARCH_ICON_COLOR = '#d32f2f';

export const idCellCSS = css`
  width: 100px;
`;

export const breakAllCellCSS = css`
  max-width: 150px;
  word-break: break-all;
  min-width: 100px;
`;

export const breakWordCellCSS = css`
  max-width: 200px;
  word-break: break-word;
  min-width: 100px;
`;

export const ToolbarCollapse = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const PickerLabelStyled = styled('div')<IThemeDependentStyledComponentProps>`
  position: relative;
  background-color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.warning.main};
  color: ${({ $themePalette }: IThemeDependentStyledComponentProps) =>
    getOppositeColor($themePalette?.warning.main as string)};

  &:hover {
    background-color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.warning.dark};
  }

  cursor: pointer;
  max-width: 150px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 20px 3px 5px;
`;

export const PickerLabelIconStyled = styled(ArrowDropDownIcon)(({ theme }) => ({
  position: 'absolute',
  top: '25%',
  right: theme.spacing(1),
  fontSize: 14,
  verticalAlign: 'middle',
}));

export const getFilterByMissedIconColor = (filterByMissed?: BooleanFilter) => {
  if (!filterByMissed) {
    return INITIAL_SEARCH_ICON_COLOR;
  }
  if (filterByMissed.value) {
    return SELECTED_MISSED_SEARCH_ICON_COLOR;
  }
  if (!filterByMissed.value) {
    return DEFAULT_SELECTED_SEARCH_ICON_COLOR;
  }
};
