import { css, Grid, styled } from '@mui/material';

export const idCellCSS = css`
  width: 1%;
  min-width: 95px;
`;

export const AppIconContainer = styled(Grid)`
  width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
`;
export const GridContainer = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr auto;
  color: ${({ theme }) => theme.palette.text.primary};
`;
